// Analytics related to the Wix Domains UPSELL page
import {
  analyticsDataRegistry,
  trackNavigationEvent,
  trackExperimentClickedEvent,
  trackExperimentViewedEvent,
  trackPageViewed,
} from '@vp/digital-tracking-lib-ubik'
const NAVIGATION_CLICKED_ACTION = 'Navigation Clicked'
export const DOMAINS_UPSELL_MAIN_HERO_LABEL = 'Content:Main Hero'
export const DOMAINS_UPSELL_MAIN_HERO_PAGE_ZONE = 'Main Hero'
export const UPGRADE_NOW_CTA_DETAIL = 'Upgrade now'
export const BUY_DOMAIN_CTA_DETAIL = 'Buy Domain'
export const CREATE_A_FREE_WEBSITE_CTA_DETAIL = 'Create a free website'
export const SUBDOMAIN_CTA_DETAIL = 'Connect it to your site'
export const SELECT_A_PREMIUM_PLAN_CTA_DETAIL = 'Select a premium plan'
export const CONTINUE_WITHOUT_WEBSITE_CTA_DETAIL = 'Continue without a website'
export const PREMIUM_SITE_FIRST_LABEL = 'Purchase Premium Site before domain'
export const PREMIUM_DOMAIN_FIRST_LABEL = 'Purchase Domain'
export const GET_A_WEBSITE_NOW_CTA_DETAIL = 'Get a Website Now'
export const TENANT_TRACKING = 'vistaprint'
export const EXPERIMENT_NAME = 'dg_merch_upsell_cta'
export const VARIATION_NAME = 'A'
export const EXPERIMENT_DETAIL = 'EXPO-1717'

const { wixDomainsUpsell } = analyticsDataRegistry
const { wixDomainsCreationUpsell } = analyticsDataRegistry

export const trackingAssetName = wixDomainsUpsell.trackingAssetName

// Fires an UPSELL page load event,
export function trackWixDomainsUpsellPageLoad (
  isWebsiteCreation = false,
  isExperiment = false
): void {
  if (isWebsiteCreation) {
    trackPageViewed(wixDomainsCreationUpsell)
  } else {
    trackPageViewed(wixDomainsUpsell)
  }
  if (isExperiment) {
    trackExperimentViewed()
  }
}

export function trackUpgradeNowCTAClick (destinationUrl: string) {
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  // Navigates to wix domains page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_UPSELL_MAIN_HERO_LABEL,
    wixDomainsUpsell, // source
    wixDestinationDomains, // target
    DOMAINS_UPSELL_MAIN_HERO_PAGE_ZONE,
    UPGRADE_NOW_CTA_DETAIL
  )
}
export function trackCreateFreeWebsiteCTAClick (destinationUrl: string) {
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  // Navigates to wix domains page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_UPSELL_MAIN_HERO_LABEL,
    wixDomainsUpsell, // source
    wixDestinationDomains, // target
    DOMAINS_UPSELL_MAIN_HERO_PAGE_ZONE,
    CREATE_A_FREE_WEBSITE_CTA_DETAIL
  )
}

export function trackBuyDomainCTAClick (
  destinationUrl: string,
  isWebsiteCreation: boolean = true
) {
  const wixSourcePage = isWebsiteCreation
    ? wixDomainsCreationUpsell
    : wixDomainsUpsell
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  // Navigates to wix domains page
  trackNavigationEvent(
    NAVIGATION_CLICKED_ACTION,
    DOMAINS_UPSELL_MAIN_HERO_LABEL,
    wixSourcePage, // source
    wixDestinationDomains, // target
    DOMAINS_UPSELL_MAIN_HERO_PAGE_ZONE,
    BUY_DOMAIN_CTA_DETAIL
  )
}

export function trackBuyDomainCTAExperimentClicked (
  destinationUrl: string,
  isWebsiteCreation: boolean = true
) {
  const wixSourcePage = isWebsiteCreation
    ? wixDomainsCreationUpsell
    : wixDomainsUpsell
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }

  trackExperimentClickedEvent(
    wixSourcePage, // sourcePage
    wixDestinationDomains, // targetPage
    DOMAINS_UPSELL_MAIN_HERO_PAGE_ZONE, // pageZone
    CREATE_A_FREE_WEBSITE_CTA_DETAIL, // ctaDetails
    TENANT_TRACKING, // trackingTenant
    EXPERIMENT_NAME, // experimentName
    VARIATION_NAME, // variationName
    EXPERIMENT_DETAIL // Experiment Detail
  )
}

export function trackCreateFreeWebsiteCTAExperimentClicked (
  destinationUrl: string,
  isWebsiteCreation: boolean = true
) {
  const wixSourcePage = isWebsiteCreation
    ? wixDomainsCreationUpsell
    : wixDomainsUpsell
  const wixDestinationDomains = {
    pageUri: destinationUrl.split('?')[0],
  }
  trackExperimentClickedEvent(
    wixSourcePage, // sourcePage
    wixDestinationDomains, // targetPage
    DOMAINS_UPSELL_MAIN_HERO_PAGE_ZONE, // pageZone
    CREATE_A_FREE_WEBSITE_CTA_DETAIL, // ctaDetails
    TENANT_TRACKING, // trackingTenant
    EXPERIMENT_NAME, // experimentName
    VARIATION_NAME, // variationName
    EXPERIMENT_DETAIL // Experiment Detail
  )
}

export function trackExperimentViewed (isWebsiteCreation: boolean = true) {
  const wixSourcePage = isWebsiteCreation
    ? wixDomainsCreationUpsell
    : wixDomainsUpsell
  trackExperimentViewedEvent(
    wixSourcePage, // sourcePage
    TENANT_TRACKING, // trackingTenant
    EXPERIMENT_NAME, // experimentName
    VARIATION_NAME, // variationName
    EXPERIMENT_DETAIL // Experiment Detail
  )
}
