import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { Fragment } from '../../components/websites-upsell-page-fragment'

export const mount: MountWithRootOptions<any> = async (
  rootElement,
  renderProps,
  rootOptions
) => {
  return clientMount(<Fragment {...renderProps} />, rootElement, rootOptions)
}
