import React, { useEffect } from 'react'
import {
  Box,
  BoundedContent,
  GridContainer,
  Row,
  Column,
  useScreenClass,
} from '@vp/swan'
import {
  analyticsDataRegistry,
} from '@vp/digital-tracking-lib-ubik'
import { useLogger, useStyles } from '@vp/ubik-context'
import { fireOptimizelyImpression } from '@vp/digital-abtest-lib-ubik'
import { useSearchedDomainValue } from '@vp/digital-search-component-lib-ubik'
import { TokenReplacer, LinkFactory } from '@vp/digital-token-replacer-lib-ubik'

import Redirect from '../../common/Redirect'
import { customerTypeEnum } from '../../common/enum/customerType'
import { HeroModel } from '@vp/digital-common-data-model-lib-ubik'
import { websiteUpsellHeroEnum } from '../../common/enum/websiteUpsellHeroKeys'

import BackButton from '../../common/backButton/BackButton'

import {
  trackBuyDomainCTAClick,
  trackCreateFreeWebsiteCTAClick,
  trackCreateFreeWebsiteCTAExperimentClicked,
  trackBuyDomainCTAExperimentClicked,
  trackUpgradeNowCTAClick,
  trackWixDomainsUpsellPageLoad,
} from './WixWebsitesUpsellPageAnalytics'
import { useLocale } from '@vp/digital-locale-lib-ubik'
import {
  getWixHowToConnectDomainHelpUrl,
  getWixPrivacyPolicyUrl,
  getWixTermsOfUseUrl,
} from '@vp/digital-url-service-lib-ubik'

import { WIX_DOMAINS_UPSELL_PAGE_EXPERIMENT_LOCALES } from '../../common/constants/experimentLocale'
import {
  experimentedCustomerType,
  experimentKeyEnum,
  upsellPageVariationsKeyEnum,
} from '../../common/enum/experimentKey'

import styles, { stylesheet } from './wixWebsitesUpsellPage.scss'
import { checkIsVistaBillingEnabled } from '../../common/helpers/domainReleaseVersionCheck'
import { urlParamsEnum } from '../../common/enum/urlParamsEnum'
import { PartnerUrls } from '../../search-and-suggestions/src/components/wixDomainsSearchAndSuggestionsPage/Types'
import { getPartnerUrls } from '../../common/helpers/wixDomainsUrls'
import { sourceEnum } from '../../common/enum/sourceEnum'
import { initializeAndFireStatsigImpression } from '../../common/helpers/domainsExperimentCheck'
import { getWixPartnerReviewAndBuyDomainUrl } from '../../common/helpers/wixDomainsUrls'
import { isProduction } from '@vp/digital-environment-lib-ubik'
import NewUserWithOwnOrNewDomain from './components/NewUserWithOwnOrNewDomain'
import FreeUserWithOwnOrNewDomain from './components/FreeUserWithOwnOrNewDomain'
import VistaBilledNewUserWithNewDomain from './components/VistaBilledNewUserWithNewDomain'
import VistaBilledFreeUserWithNewDomain from './components/VistaBilledFreeUserWithNewDomain'
import NewUserWithNewDomainWithExp from './components/NewUserWithNewDomainWithExp'
import FreeUserWithNewDomain from './components/FreeUserWithNewDomain'

const WixWebsitesUpsellPage = (pageContext) => {
  useStyles(stylesheet)
  const { languageCode } = useLocale()
  const isMobile = useScreenClass() === 'xs'
  const [domain] = useSearchedDomainValue()

  const [redirectPath, setRedirectPath] = React.useState(null)
  const [isRedirecting, setIsRedirecting] = React.useState(false)
  const [customerTypeParam, setCustomerTypeParam] = React.useState(null)
  const [websiteUpsellPageData, setWebsiteUpsellPageData] =
    React.useState(null)

  const logger = useLogger()

  const { websiteUpsellHeroGroup } = pageContext.pageData

  const {
    urlWixPartnerGetStartedTnCAcceptedlUrlsUpsell,
    commonLocalizedUrls: { wixWebsitesInitialPlansUrlLocalized },
    locale,
  } = pageContext

  const isVistaBilled = checkIsVistaBillingEnabled(locale)
  const urlWixPartnerGetStartedTnCAcceptedlUrl = isProduction()
    ? urlWixPartnerGetStartedTnCAcceptedlUrlsUpsell.prod
    : urlWixPartnerGetStartedTnCAcceptedlUrlsUpsell.qa

  const { wixDomainsUpsell } = analyticsDataRegistry

  const wixPartnerHowToConnectDomainUrl =
    getWixHowToConnectDomainHelpUrl(languageCode)

  const siteId: string = ''
  let querySiteId: string
  let queryCustomerTypeParam: string
  const source: string = sourceEnum.DOMAINS_WEBSITE_UPSELL
  let domainName: string = domain ?? null

  if (typeof window !== 'undefined' && window.location?.search) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    queryCustomerTypeParam = urlParams?.get(urlParamsEnum.CUSTOMER_TYPE)
    querySiteId =
      urlParams?.get(urlParamsEnum.SITE_ID) ??
      urlParams?.get(urlParamsEnum.UPGRADE_SITE_ID)
    domainName = urlParams?.get(urlParamsEnum.DOMAIN_NAME) ?? domain
  }

  const partnerUrls: PartnerUrls = getPartnerUrls(
    source,
    locale,
    domainName,
    querySiteId,
    false,
    source
  )

  useEffect(() => {
    if (queryCustomerTypeParam) {
      if (
        experimentedCustomerType.includes(
          queryCustomerTypeParam as customerTypeEnum
        ) &&
        WIX_DOMAINS_UPSELL_PAGE_EXPERIMENT_LOCALES.includes(locale)
      ) {
        let customerTypeVariation2
        if (
          queryCustomerTypeParam === customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN
        ) {
          customerTypeVariation2 =
            customerTypeEnum.VISTA_BILLED_NEW_USER_WITH_NEW_DOMAIN
        } else if (
          queryCustomerTypeParam === customerTypeEnum.FREE_USER_WITH_NEW_DOMAIN
        ) {
          customerTypeVariation2 =
            customerTypeEnum.VISTA_BILLED_FREE_USER_WITH_NEW_DOMAIN
        } else if (
          queryCustomerTypeParam ===
          customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT
        ) {
          customerTypeVariation2 =
            customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT
        }

        if (
          fireOptimizelyImpression(
            experimentKeyEnum.WIX_DOMAINS_UPSELL_PAGE_VARIATIONS,
            logger
          ) === upsellPageVariationsKeyEnum.VAR2_DOMAIN_CTA_ON_THE_SIDE
        ) {
          logger.debug(`Experiment fired for locale ${locale}`)
          setCustomerTypeParam(customerTypeVariation2)
        } else setCustomerTypeParam(queryCustomerTypeParam)
      } else setCustomerTypeParam(queryCustomerTypeParam)
    }
  }, [queryCustomerTypeParam])

  // Analytics
  useEffect(() => {
    if (customerTypeParam) {
      const isWebsiteCreation =
        customerTypeParam === customerTypeEnum.NEW_USER_WITH_OWN_DOMAIN ||
        customerTypeParam ===
          customerTypeEnum.VISTA_BILLED_NEW_USER_WITH_NEW_DOMAIN ||
        customerTypeParam === customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN
      const isExperiment =
        customerTypeParam ===
        customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT
      trackWixDomainsUpsellPageLoad(isWebsiteCreation, isExperiment)
    }
    // Fire experiment viewed tracking for MIR from Domain Search Pricing Experiment
    initializeAndFireStatsigImpression(
      experimentKeyEnum.WIX_DOMAINS_SEARCH_PRICING_EXPERIMENT,
      wixDomainsUpsell
    )
  }, [pageContext.locale, pageContext.trackingKeys, customerTypeParam])

  useEffect(() => {
    setWebsiteUpsellPageData(getWebsiteUpsellPageData(customerTypeParam))
  }, [customerTypeParam])

  const getWebsiteUpsellPageData = (
    customerType: customerTypeEnum
  ): HeroModel => {
    let websiteUpsellPageData: any
    switch (customerType) {
      case customerTypeEnum.NEW_USER_WITH_OWN_DOMAIN:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key === websiteUpsellHeroEnum.NEW_USER_WITH_OWN_DOMAIN
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          descriptionLong: (
            <TokenReplacer
              template={websiteUpsellPageData?.descriptionLong}
              replacements={{
                link: LinkFactory({
                  wixTermsOfUseUrl: getWixTermsOfUseUrl(languageCode),
                  wixPrivacyPolicyUrl: getWixPrivacyPolicyUrl(languageCode),
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
      case customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key === websiteUpsellHeroEnum.NEW_USER_WITH_NEW_DOMAIN
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          title: (
            <TokenReplacer
              template={websiteUpsellPageData?.title}
              replacements={{
                domainSearched: domainName,
              }}
              convertMarkdown
            />
          ),
          descriptionLong: (
            <TokenReplacer
              template={websiteUpsellPageData?.descriptionLong}
              replacements={{
                link: LinkFactory({
                  wixTermsOfUseUrl: getWixTermsOfUseUrl(languageCode),
                  wixPrivacyPolicyUrl: getWixPrivacyPolicyUrl(languageCode),
                }),
              }}
              convertMarkdown
            />
          ),
          footer: (
            <TokenReplacer
              template={websiteUpsellPageData?.footer?.replace(
                '{{ domainSearched }}',
                domainName
              )}
              replacements={{
                link: LinkFactory({
                  wixTermsOfUseUrl: getWixTermsOfUseUrl(languageCode),
                  wixPrivacyPolicyUrl: getWixPrivacyPolicyUrl(languageCode),
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
      case customerTypeEnum.FREE_USER_WITH_OWN_DOMAIN:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key === websiteUpsellHeroEnum.FREE_USER_WITH_OWN_DOMAIN
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          title: (
            <TokenReplacer
              template={websiteUpsellPageData?.title}
              replacements={{
                domainSearched: domainName,
              }}
              convertMarkdown
            />
          ),
          footer: (
            <TokenReplacer
              template={' ' + websiteUpsellPageData?.footer}
              replacements={{
                link: LinkFactory({
                  wixHowToConnectDomainUrl: wixPartnerHowToConnectDomainUrl,
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
      case customerTypeEnum.FREE_USER_WITH_NEW_DOMAIN:
        {
          const domainPurchaseUrl = isVistaBilled
            ? partnerUrls.vistaDomainRegistrationUrl
            : wixPartnerReviewAndBuyDomainsUrl
          websiteUpsellPageData = websiteUpsellHeroGroup?.find(
            ({ key }: { key: string }) =>
              key === websiteUpsellHeroEnum.FREE_USER_WITH_NEW_DOMAIN
          )
          websiteUpsellPageData = {
            ...websiteUpsellPageData,
            title: (
              <TokenReplacer
                template={websiteUpsellPageData?.title}
                replacements={{
                  domainSearched: domainName,
                }}
                convertMarkdown
              />
            ),
            descriptionLong: (
              <TokenReplacer
                template={websiteUpsellPageData?.descriptionLong}
                replacements={{
                  link: LinkFactory({
                    wixStandaloneDomainsPurchaseUrl: domainPurchaseUrl,
                  }),
                }}
                convertMarkdown
              />
            ),
            footer: (
              <TokenReplacer
                template={websiteUpsellPageData?.footer.replace(
                  '{{ domainSearched }}',
                  domainName
                )}
                replacements={{
                  link: LinkFactory(
                    {
                      wixStandaloneDomainsPurchaseUrl: domainPurchaseUrl,
                    },
                    () => {
                      handleBuyDomain()
                    },
                    '_self'
                  ),
                }}
                convertMarkdown
              />
            ),
          }
        }
        break
      case customerTypeEnum.FREE_USER_WITH_NEW_OWN_DOMAIN:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key === websiteUpsellHeroEnum.FREE_USER_WITH_NEW_OWN_DOMAIN
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          title: (
            <TokenReplacer
              template={websiteUpsellPageData?.title}
              replacements={{
                domainSearched: domainName,
              }}
              convertMarkdown
            />
          ),
          footer: (
            <TokenReplacer
              template={' ' + websiteUpsellPageData?.footer}
              replacements={{
                link: LinkFactory({
                  wixHowToConnectDomainUrl: wixPartnerHowToConnectDomainUrl,
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
      case customerTypeEnum.VISTA_BILLED_NEW_USER_WITH_NEW_DOMAIN:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key === websiteUpsellHeroEnum.VISTA_BILLED_NEW_USER_WITH_NEW_DOMAIN
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          title: (
            <TokenReplacer
              template={websiteUpsellPageData?.title}
              replacements={{
                domainSearched: domainName,
              }}
              convertMarkdown
            />
          ),
          footer: (
            <TokenReplacer
              template={' ' + websiteUpsellPageData?.footer}
              replacements={{
                link: LinkFactory({
                  wixHowToConnectDomainUrl: wixPartnerHowToConnectDomainUrl,
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
      case customerTypeEnum.VISTA_BILLED_FREE_USER_WITH_NEW_DOMAIN:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key === websiteUpsellHeroEnum.VISTA_BILLED_FREE_USER_WITH_NEW_DOMAIN
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          title: (
            <TokenReplacer
              template={websiteUpsellPageData?.title}
              replacements={{
                domainSearched: domainName,
              }}
              convertMarkdown
            />
          ),
          descriptionLong: (
            <TokenReplacer
              template={websiteUpsellPageData?.descriptionLong}
              replacements={{
                link: LinkFactory({
                  wixStandaloneDomainsPurchaseUrl:
                    wixPartnerReviewAndBuyDomainsUrl,
                }),
              }}
              convertMarkdown
            />
          ),
          footer: (
            <TokenReplacer
              template={' ' + websiteUpsellPageData?.footer}
              replacements={{
                link: LinkFactory({
                  wixHowToConnectDomainUrl: wixPartnerHowToConnectDomainUrl,
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
      case customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT:
        websiteUpsellPageData = websiteUpsellHeroGroup?.find(
          ({ key }: { key: string }) =>
            key ===
            websiteUpsellHeroEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT
        )
        websiteUpsellPageData = {
          ...websiteUpsellPageData,
          title: (
            <TokenReplacer
              template={websiteUpsellPageData?.title}
              replacements={{
                domainSearched: domainName,
              }}
              convertMarkdown
            />
          ),
          descriptionLong: (
            <TokenReplacer
              template={websiteUpsellPageData?.descriptionLong}
              replacements={{
                link: LinkFactory({
                  wixStandaloneDomainsPurchaseUrl:
                    wixPartnerReviewAndBuyDomainsUrl,
                }),
              }}
              convertMarkdown
            />
          ),
          footer: (
            <TokenReplacer
              template={' ' + websiteUpsellPageData?.footer}
              replacements={{
                link: LinkFactory({
                  wixHowToConnectDomainUrl: wixPartnerHowToConnectDomainUrl,
                }),
              }}
              convertMarkdown
            />
          ),
        }
        break
    }

    return websiteUpsellPageData
  }

  function handleUpgradeNowNavigation () {
    setIsRedirecting(true)
    const { url } = wixWebsitesInitialPlansUrlLocalized
    trackUpgradeNowCTAClick(url + `?SiteId=${querySiteId}`)
    setRedirectPath(url + `?SiteId=${querySiteId}`)
  }

  // For standalone domain purchase without upgrading site, hence empty siteId sent
  const wixPartnerReviewAndBuyDomainsUrl = getWixPartnerReviewAndBuyDomainUrl(
    locale,
    source,
    domainName,
    source,
    siteId
  )

  function handleBuyDomain () {
    setIsRedirecting(true)
    const url = wixPartnerReviewAndBuyDomainsUrl
    if (
      queryCustomerTypeParam &&
      queryCustomerTypeParam ===
        customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT
    ) {
      trackBuyDomainCTAExperimentClicked(url)
    } else {
      trackBuyDomainCTAClick(url)
    }
    setRedirectPath(url)
  }

  function handleVistaBuyDomain (isWebsiteCreation: boolean = true) {
    setIsRedirecting(true)
    const url = partnerUrls.vistaDomainRegistrationUrl
    trackBuyDomainCTAClick(url, isWebsiteCreation)
    setRedirectPath(url)
  }

  function handleCreateFreeWebsiteNavigation () {
    setIsRedirecting(true)
    let url = urlWixPartnerGetStartedTnCAcceptedlUrl
    if (isMobile) {
      url += `&${urlParamsEnum.DEVICE_TYPE}=mobile`
    }
    if (
      queryCustomerTypeParam &&
      queryCustomerTypeParam ===
        customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT
    ) {
      trackCreateFreeWebsiteCTAExperimentClicked(url)
    } else {
      trackCreateFreeWebsiteCTAClick(url)
    }

    setRedirectPath(url)
  }

  return (
    <Redirect redirectPath={redirectPath} isRedirecting={isRedirecting}>
      <BoundedContent>
        <Box>
          {websiteUpsellPageData && (
            <>
              <BackButton text={websiteUpsellPageData?.ctaName} />
              <GridContainer>
                <Row>
                  <Column
                    span={12}
                    spanMd={10}
                    offsetMd={1}
                    spanLg={10}
                    offsetLg={1}
                    spanXl={10}
                    offsetXl={1}
                    className={styles.wixWebsitesUpsellContainer}
                    my={{ xs: '7', sm: '7', md: 12, lg: 12, xl: 12 }}
                  >
                    {(customerTypeParam ===
                      customerTypeEnum.NEW_USER_WITH_OWN_DOMAIN ||
                      customerTypeParam ===
                        customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN) && (
                          <NewUserWithOwnOrNewDomain
                            websiteUpsellPageData={websiteUpsellPageData}
                            handleCreateFreeWebsiteNavigation={
                          handleCreateFreeWebsiteNavigation
                        }
                          />
                    )}

                    {customerTypeParam ===
                      customerTypeEnum.FREE_USER_WITH_NEW_DOMAIN && (
                        <FreeUserWithNewDomain
                          websiteUpsellPageData={websiteUpsellPageData}
                          handleUpgradeNowNavigation={handleUpgradeNowNavigation}
                        />
                    )}

                    {(customerTypeParam ===
                      customerTypeEnum.FREE_USER_WITH_OWN_DOMAIN ||
                      customerTypeParam ===
                        customerTypeEnum.FREE_USER_WITH_NEW_OWN_DOMAIN) && (
                          <FreeUserWithOwnOrNewDomain
                            websiteUpsellPageData={websiteUpsellPageData}
                            handleUpgradeNowNavigation={handleUpgradeNowNavigation}
                          />
                    )}

                    {customerTypeParam ===
                      customerTypeEnum.VISTA_BILLED_NEW_USER_WITH_NEW_DOMAIN && (
                        <VistaBilledNewUserWithNewDomain
                          websiteUpsellPageData={websiteUpsellPageData}
                          domainName={domainName}
                          handleCreateFreeWebsiteNavigation={
                          handleCreateFreeWebsiteNavigation
                        }
                          handleVistaBuyDomain={handleVistaBuyDomain}
                        />
                    )}

                    {customerTypeParam ===
                      customerTypeEnum.VISTA_BILLED_FREE_USER_WITH_NEW_DOMAIN && (
                        <VistaBilledFreeUserWithNewDomain
                          websiteUpsellPageData={websiteUpsellPageData}
                          domainName={domainName}
                          handleUpgradeNowNavigation={handleUpgradeNowNavigation}
                          handleVistaBuyDomain={handleVistaBuyDomain}
                        />
                    )}

                    {customerTypeParam ===
                      customerTypeEnum.NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT && (
                        <NewUserWithNewDomainWithExp
                          websiteUpsellPageData={websiteUpsellPageData}
                          domainName={domainName}
                          handleCreateFreeWebsiteNavigation={
                          handleCreateFreeWebsiteNavigation
                        }
                          handleBuyDomain={handleBuyDomain}
                        />
                    )}
                  </Column>
                </Row>
              </GridContainer>
            </>
          )}
        </Box>
      </BoundedContent>
    </Redirect>
  )
}

export default WixWebsitesUpsellPage
