export enum websiteUpsellHeroEnum {
  NEW_USER_WITH_OWN_DOMAIN = 'newUserWithOwnDomain',
  NEW_USER_WITH_NEW_DOMAIN = 'newUserWithNewDomain',
  NEW_USER_WITH_NEW_DOMAIN_WITH_EXPERIMENT = 'newUserWithNewDomainWithExperiment',
  NEW_USER_WITH_OLD_DOMAIN_WITH_EXPERIMENT = 'newUserWithOwnDomainWithExperiment',
  FREE_USER_WITH_NEW_DOMAIN = 'freeUserWithNewDomain',
  FREE_USER_WITH_OWN_DOMAIN = 'freeUserWithOwnDomain',
  FREE_USER_WITH_NEW_OWN_DOMAIN = 'freeUserWithNewOwnDomain',
  VISTA_BILLED_NEW_USER_WITH_NEW_DOMAIN = 'vistaBilledNewUserWithNewDomain',
  VISTA_BILLED_FREE_USER_WITH_NEW_DOMAIN = 'vistaBilledFreeUserWithNewDomain',
}
