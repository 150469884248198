import React from 'react'
import {
  H1,
  P,
  Button,
  H2,
  Visible,
  Box,
  Column,
  Divider,
  Row,
} from '@vp/swan'

import { TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'

type VistaBilledNewUserWithNewDomainProps = {
  websiteUpsellPageData: any;
  domainName: string;
  handleCreateFreeWebsiteNavigation: () => void;
  handleVistaBuyDomain: (e: boolean) => void;
}
const VistaBilledNewUserWithNewDomain = ({
  websiteUpsellPageData,
  domainName,
  handleCreateFreeWebsiteNavigation,
  handleVistaBuyDomain,
}: VistaBilledNewUserWithNewDomainProps) => {
  return (
    <>
      <Row>
        <Column
          span={12}
          spanMd={4}
          spanLg={4}
          spanXl={4}
          textAlign={{
            xs: 'center',
            sm: 'center',
            md: 'left',
            lg: 'left',
            xl: 'left',
          }}
        >
          <>
            <H1 id='title1'>{websiteUpsellPageData?.title}</H1>
            <P mt={7} m={0} id='description1'>
              {websiteUpsellPageData?.description}
            </P>
            <Button
              mt={7}
              skin='primary'
              onClick={handleCreateFreeWebsiteNavigation}
              id='cta1'
            >
              {websiteUpsellPageData?.secondaryCtaName}
            </Button>
          </>
        </Column>
        <Column span={12} spanMd={4} spanLg={4} spanXl={4}>
          <Visible
            md
            lg
            xl
            className='wix-websites-upsell-vista-billing-divider-container'
          >
            <Divider className='wix-websites-upsell-vista-billing-divider' />
            <P>{websiteUpsellPageData?.footer}</P>
            <Divider className='wix-websites-upsell-vista-billing-divider' />
          </Visible>
          <Visible xs sm m='6'>
            <Box className='wix-websites-upsell-or-container-mob'>
              <Box />
              <P>{websiteUpsellPageData?.footer}</P>
              <Box />
            </Box>
          </Visible>
        </Column>
        <Column
          span={12}
          spanMd={4}
          spanLg={4}
          spanXl={4}
          textAlign={{
            xs: 'center',
            sm: 'center',
            md: 'left',
            lg: 'left',
            xl: 'left',
          }}
        >
          <>
            <div className='wix-websites-upsell-buy-domain-container'>
              <H2 id='title2'>
                <TokenReplacer
                  template={websiteUpsellPageData?.heroMetadata[0]?.title}
                  replacements={{
                    domainSearched: domainName,
                  }}
                  convertMarkdown
                />
              </H2>
              <P mt={7} m={0} id='description2'>
                {websiteUpsellPageData?.heroMetadata[0]?.description}
              </P>
              <div className='wix-websites-upsell-buy-domain-btn-holder'>
                <Button
                  mt={7}
                  skin='secondary'
                  onClick={() => {
                    handleVistaBuyDomain(true)
                  }}
                  id='cta2'
                >
                  {websiteUpsellPageData?.tertiaryCtaName}
                </Button>
              </div>
            </div>
          </>
        </Column>
      </Row>
    </>
  )
}

export default VistaBilledNewUserWithNewDomain
