import React from 'react'
import { H1, P, Button, Box, Column, Divider, Row, Visible } from '@vp/swan'

import {
  CONTINUE_WITHOUT_WEBSITE_CTA_DETAIL,
  DOMAINS_UPSELL_MAIN_HERO_LABEL,
  SELECT_A_PREMIUM_PLAN_CTA_DETAIL,
} from '../WixWebsitesUpsellPageAnalytics'
import { TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'

type NewUserWithNewDomainWithExpProps = {
  websiteUpsellPageData: any;
  domainName: string;
  handleCreateFreeWebsiteNavigation: () => void;
  handleBuyDomain: () => void;
}
const NewUserWithNewDomainWithExp = ({
  websiteUpsellPageData,
  domainName,
  handleCreateFreeWebsiteNavigation,
  handleBuyDomain,
}: NewUserWithNewDomainWithExpProps) => {
  return (
    <>
      <Row>
        <Column
          span={12}
          spanMd={4}
          spanLg={4}
          spanXl={4}
          textAlign={{
            xs: 'center',
            sm: 'center',
            md: 'left',
            lg: 'left',
            xl: 'left',
          }}
        >
          <>
            <H1
              fontSize='x2large'
              className='wix-websites-upsell__hero__title-with-domain'
              id='title1'
            >
              {websiteUpsellPageData?.title}
            </H1>
            <P mt={7} m={0} id='description1'>
              {websiteUpsellPageData?.descriptionLong}
            </P>
            <Button
              mt={7}
              skin='secondary'
              onClick={handleCreateFreeWebsiteNavigation}
              data-section={DOMAINS_UPSELL_MAIN_HERO_LABEL}
              data-position='1'
              data-translation={SELECT_A_PREMIUM_PLAN_CTA_DETAIL}
              id='cta1'
            >
              {websiteUpsellPageData?.secondaryCtaName}
            </Button>
          </>
        </Column>
        <Column span={12} spanMd={4} spanLg={4} spanXl={4}>
          <Visible
            md
            lg
            xl
            className='wix-websites-upsell-vista-billing-divider-container'
          >
            <Divider className='wix-websites-upsell-vista-billing-divider' />
            <P>{websiteUpsellPageData?.footer}</P>
            <Divider className='wix-websites-upsell-vista-billing-divider' />
          </Visible>
          <Visible xs sm m='6'>
            <Box className='wix-websites-upsell-or-container-mob'>
              <Box />
              <P>{websiteUpsellPageData?.footer}</P>
              <Box />
            </Box>
          </Visible>
        </Column>
        <Column
          span={12}
          spanMd={4}
          spanLg={4}
          spanXl={4}
          textAlign={{
            xs: 'center',
            sm: 'center',
            md: 'left',
            lg: 'left',
            xl: 'left',
          }}
        >
          <>
            <Box className='wix-websites-upsell-buy-domain-container'>
              <H1
                fontSize='x2large'
                className='wix-websites-upsell__hero__title-with-domain'
                id='title2'
              >
                <TokenReplacer
                  template={websiteUpsellPageData?.heroMetadata[0]?.title}
                  replacements={{
                    domainSearched: domainName,
                  }}
                  convertMarkdown
                />
              </H1>
              <P mt={7} m={0} id='description2'>
                {websiteUpsellPageData?.heroMetadata[0]?.description}
              </P>
              <Box>
                <Button
                  mt={7}
                  skin='primary'
                  onClick={() => {
                    handleBuyDomain()
                  }}
                  data-section={DOMAINS_UPSELL_MAIN_HERO_LABEL}
                  data-position='2'
                  data-translation={CONTINUE_WITHOUT_WEBSITE_CTA_DETAIL}
                  id='cta2'
                >
                  {websiteUpsellPageData?.tertiaryCtaName}
                </Button>
              </Box>
            </Box>
          </>
        </Column>
      </Row>
    </>
  )
}

export default NewUserWithNewDomainWithExp
