import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { UpsellApp } from '../web/websites-upsell/UpsellApp'

export const Fragment = (props: any) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  useSwanStyleKeys(['core'])

  return (
    <UpsellApp {...props} />
  )
}
