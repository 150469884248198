import { H1, P, Button, Box } from '@vp/swan'

import {
  DOMAINS_UPSELL_MAIN_HERO_LABEL,
  UPGRADE_NOW_CTA_DETAIL,
} from '../WixWebsitesUpsellPageAnalytics'

type FreeUserWithNewDomainProps = {
  websiteUpsellPageData: any;
  handleUpgradeNowNavigation: () => void;
}
const FreeUserWithNewDomain = ({
  websiteUpsellPageData,
  handleUpgradeNowNavigation,
}: FreeUserWithNewDomainProps) => {
  return (
    <>
      <H1 id='title'>{websiteUpsellPageData?.title}</H1>
      <Button
        mt={7}
        skin='primary'
        onClick={handleUpgradeNowNavigation}
        data-section={DOMAINS_UPSELL_MAIN_HERO_LABEL}
        data-position='1'
        data-translation={UPGRADE_NOW_CTA_DETAIL}
        id='cta'
      >
        {websiteUpsellPageData?.secondaryCtaName}
      </Button>
      <P mt={7} m={0} id='long-description'>
        {websiteUpsellPageData?.descriptionLong}
      </P>
      <Box mt={7} className='wix-websites-upsell__or__container'>
        <Box />
        <P m={0} id='description'>
          {websiteUpsellPageData?.description}
        </P>
        <Box />
      </Box>
      <P mt={7} m={0} id='footer'>
        {websiteUpsellPageData?.footer}
      </P>
    </>
  )
}

export default FreeUserWithNewDomain
